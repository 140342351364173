import React from "react";

function ServicePage() {
  return (
    <>
      <div className="container-fluid background-color">
        <div className="row">
          <div className="col-xl-12 py-3">
            <h1 className="fw-bold text-center display-6">
              Servic<span className="color">es</span>
            </h1>
          </div>
        </div>
      </div>
     
      <div className="container-fluid">
      <div className="row my-5 justify-content-center px-2">
        <div className="col-xl-10 mb-3">
          <h1 className="display-5 fw-bold text-center">
            We begin our journey together by getting a sense of your definition
            and experience of success.
          </h1>
        </div>
        <div className="col-xl-5">
          <div>
            <img
              src={require("../../../Image/service-5.png")}
              alt=""
              height="80px"
              width="auto"
            />
            <h1 className="fw-bold fs-3">
              App Development | Web Design & Development | UI/UX Design
            </h1>
            <p>
              {" "}
              <i className="bi bi-check-circle color"></i> Lively Web Pages
            </p>
            <p>
              {" "}
              <i className="bi bi-check-circle color"></i> Solutions for
              E-Commerce
            </p>
            <p>
              {" "}
              <i className="bi bi-check-circle color"></i> Solutions for UI/UX
            </p>
            <p>
              {" "}
              <i className="bi bi-check-circle color"></i>Design of Landing
              Pages
            </p>
            <p>
              {" "}
              <i className="bi bi-check-circle color"></i> Applications for
              Mobile
            </p>
            <p>
              {" "}
              <i className="bi bi-check-circle color"></i> API-Based
              Connectivity
            </p>
            <p>
              {" "}
              <i className="bi bi-check-circle color"></i> Designing Inclusive
              Experiences
            </p>{" "}
            <p>
              {" "}
              <i className="bi bi-check-circle color"></i> Configuring a Blog
            </p>{" "}
            <p>
              {" "}
              <i className="bi bi-check-circle color"></i> Community-Oriented
              Websites
            </p>
          </div>
        </div>
        <div className="col-xl-4 Content-center">
          <div>
            <img
              src={require("../../../Image/service/Web-Design-&-Development.png")}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </div>
        <div className="row my-5 justify-content-center py-3 px-2">
          <div className="col-xl-5">
            <div>
              <img
                src={require("../../../Image/service-2.png")}
                alt=""
                height="90px"
                width="auto"
              />
              <h1 className="fw-bold fs-3">
                The Creative and Digital Approach
              </h1>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Branding
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Innovative Services
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Inside-Out
                Campaigns
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Designing Logos
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Creative Management
                for Social Media
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Graphic and Brand
                Design
              </p>
            </div>
          </div>
          <div className="col-xl-4 Content-center">
            <div>
              <img
                src={require("../../../Image/service/Creative-&-Digital-Strategy.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        {/*  */}
        <div className="row my-5 justify-content-around background-color py-5 px-2 row-reverse">
          <div className="col-xl-5">
            <div>
              <img
                src={require("../../../Image/service-1.png")}
                alt=""
                height="100px"
                width="auto"
              />
              <h1 className="fw-bold fs-3">
                Planning and Purchasing for Media
              </h1>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Digital Media
                Purchasing and Planning
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Programming
                Solutions
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i>Digital Lead
                Generation
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> SMS advertising
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Sponsored Social
                Media
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Email-Based
                Campaigns
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Strategy for Mobile
                Media
              </p>
            </div>
          </div>
          <div className="col-xl-4 Content-center">
            <div>
              <img
                src={require("../../../Image/service/Media-Planning-&-Buying.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        {/*  */}
        <div className="row my-5 justify-content-center py-5 px-2">
          <div className="col-xl-5">
            <div>
              <img
                src={require("../../../Image/service-3.png")}
                alt=""
                height="80px"
                width="auto"
              />
              <h1 className="fw-bold fs-3">
                Analytical Tools and SEO Strategies
              </h1>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Examination of
                Websites
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Research on
                Keywords and Rivals
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Sem Strategy Using
                Chosen Keywords
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Campaign Management
                for Google Adwords
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Advertising in
                Context
              </p>
            </div>
          </div>
          <div className="col-xl-4 Content-center">
            <div>
              <img
                src={require("../../../Image/service/Analytics-&-SEO.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        {/*  */}
        <div className="row my-5 justify-content-center background-color py-5 px-2 row-reverse">
          <div className="col-xl-6 d-flex justify-content-end">
            <div>
              <img
                src={require("../../../Image/service-4.png")}
                alt=""
                height="80px"
                width="auto"
              />
              <h1 className="fw-bold fs-3">
                Digital communications and branding Internet Promotion
              </h1>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Social Media
                Supervision
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Digital Methods
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Methods for Search
                Engine Optimization
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Marketing of
                Content
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Management of
                Online Reputation
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Email Promotion
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Management of Paid
                Media
              </p>
              <p>
                {" "}
                <i className="bi bi-check-circle color"></i> Advertisement
                through Programmatic
              </p>
            </div>
          </div>{" "}
          <div className="col-xl-4 Content-center">
            <div>
              <img
                src={require("../../../Image/service/Branding-&-Digital-Communications.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        {/*  */}
      </div>
    </>
  );
}

export default ServicePage;
